.policyPageBody {
    padding: 0px 15vw 20px 15vw;
    min-height: 75vh;
    font-size: 1.5rem;
}

@media (max-width: 640px) {
    .policyPageBody {
        padding: 0px 10vw 20px 10vw;
        font-size: 1.2rem;
    }
}