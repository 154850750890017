.header {
    height: 64px;
    padding: 10px;
    color: white;
    font-size: 1rem;
    font-family: inherit;
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    text-shadow: 0 0 5px #333;
    z-index: 1;
}

.headerDark {
    background-color: var(--brand-colour1);
}

.headerLogoSchool {
    background: white;
    color: black;
    text-shadow: 0;
    height: 44px;
    min-width: 44px;
    margin-right: 10px;
    border-radius: 50%;
}

.headerLogoSchoolName {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    height: 44px;
}

.headerLogo {
    font-family: inherit;
    height: 48px;
    min-width: 150px;
    background: url('../images/outdr_logo_light_144.png') no-repeat;
    background-size: auto 36px; 
    background-position: left center;
}

.headerLogoDark {
    font-family: inherit;
    flex: 0.5;
    min-width: 150px;
    color: white;
    height: 48px;
    background: url('../images/outdr_logo_light_144.png') no-repeat;
    background-size: auto 36px; 
    background-position: left center;
}

.headerItems {
    flex: 1;
    height: 100%;
    font-size: 1rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;
}

.headerItems a {
    color: white;
    padding: 5px;
}

.manageSchool {
    color: white;
}

.userIcon, .languageIcon {
    height: 24px;
    width: 24px;
    margin-left: 5px; 
    margin-right: 5px;
    margin-top: 5px;
}

.bookingsIcon {
    height: 28px;
    width: 28px;
    margin-left: 5px; 
    margin-right: 1px;
    margin-top: 5px;
}

.btnHeader {
    padding: 10px 15px;
    color: white;
    border-radius: 28px;
    background: transparent;
    border: 1px solid white;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.4);
    max-height: 40px;
    min-height: 40px;
    font-size: 1rem;
    text-transform: uppercase;
    margin-left: 10px;
  }
  
  .btnHeader:hover {
    border: 1px solid var(--brand-colour5);
    background-color: var(--brand-colour5);
    color: black;
    text-shadow: none;
  }

  .header .dropdown-content {
      background-color: white;
      color: black;
      text-shadow: none;
  }
