.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    display: none;
    position: absolute;
    top: 25px;
    right: 0px;
    border-radius: 2px;
    box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}