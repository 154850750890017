.forbidden {
    font-family: inherit;
    font-size: 2rem;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 50px;
    background: rgba(255, 174, 0, 0.7);
    border-radius: 2px;
}