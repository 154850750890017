.footerContainer {
    background: var(--brand-colour2);
    padding-bottom: 200px;
}

.footer {
    color: #222;
    font-size: 1.2rem;
    padding: 50px;
}

.footer > div {
    padding-right: 50px;
}

.footer > div > b {
    font-size: 1.4rem;
    color: black;
    margin-bottom: 5px;
}

.footerLogo {
    font-family: inherit;
    height: 48px;
    min-width: 150px;
    background: url('../images/outdr_logo_footer.png') no-repeat;
    background-size: auto 36px; 
    background-position: left center;
}

.footerCopyright {
    font-size: 1rem;
    color: #666;
}

@media (max-width: 640px) {
    .footerContainer {
        padding-bottom: 100px;
    }

    .footer {
        padding: 20px;
        font-size: 12px;
    }

    .footer > div {
        padding-right: 10px;
    }

    .footer > div > b {
        font-size: 14px;
    }
}
