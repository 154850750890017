.errorMessage {
    font-family: inherit;
    font-size: 1.5rem;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin-top: 50px;
    border-radius: 2px;
    background: rgba(255, 0, 0, 0.7);
}