.loading {
    font-family: inherit;
    font-size: 1rem;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 20px;

    background: url('../../images/outdr_logo_mark.png') no-repeat;
    background-size: auto 50px; 
    background-position: center;
}

.loading.dark {
    font-family: inherit;
    font-size: 1rem;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 20px;

    background: url('../../images/outdr_logo_mark.png') no-repeat;
    background-size: auto 50px; 
    background-position: center;
}

.loading.dark .sk-cube {
    /* spinner default white -> transparent black */
    background-color: black;
    mix-blend-mode: difference;
}