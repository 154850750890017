.App {
  display: flex;
  flex-flow: column wrap;
  color: #FFF;
}

.btn {
  font-family: 'Work Sans';
  font-size: 1rem;
  font-weight: 400;
  outline: none;
  color: #2F312A;
  background: transparent;
  border: 0;
  border-radius: 2px;
  width: 100%;
  max-width: 200px;
  min-width: 100px;
  height: calc(1em*2 + 6px);
  margin-top: 5px;
  margin-bottom: 5px;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
  text-transform: uppercase;
  text-align: center;
  user-select: none;
  transition: background-color 0.5s ease;
}

.btn + .btn {
  margin-left: 5px;
}

.btn:hover {
  color: black;
  cursor: pointer;
}

.btn:active {
  transform: translateY(3px);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
}

.btn:disabled,
.btn:hover:disabled {
  border: solid 1px #CCC;
  color: #CCC;
  background: transparent;
  box-shadow: none;
}

.btn-primary {
  background: linear-gradient(to left, #A8FFB0, #D8FF72);
}

.btn-secondary {
  border: 1px solid #A8FFB0;
}

.btn-danger {
  border: 1px solid var(--brand-colour4);
}

.btn-danger:hover {
  background-color: var(--brand-colour4);
}

.btn-primary:hover {
  background: #D8FF72;
}

.btn-secondary:hover {
  background: #A8FFB0;
}

.btn-add {
  width: 40px;
  height: 30px;
  min-width: 0px;
}

.btn-small {
  background: var(--brand-colour2);
  border: 1px solid var(--brand-colour2);
  color: #444;
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  text-transform: none;
  box-shadow: none;
  border-radius: 20px;
  transition: background-color 0.5s ease, box-shadow 0.5s ease, border-color 0.5s ease;
}

.btn-small-highlight,
.btn-small:hover {
  background: white;
  border: 1px solid var(--brand-colour3);
  color: var(--brand-colour3);
  text-transform: none;
  box-shadow: none;
  border-radius: 20px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1);
}

.btn-small-pulse:disabled,
.btn-small:disabled:hover,
.btn-small:disabled {
  color: #CCC;
  cursor: unset;
}

.btn-vsmall {
  background: white;
  border: 1px solid var(--brand-colour3);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  color: var(--brand-colour3);
  width: 20px;
  height: 20px;
  min-width: 0px;
  text-transform: none;
  border-radius: 10px;
  font-size: 10px;
  text-align: center;
  transition: background-color 0.5s ease, box-shadow 0.5s ease, border-color 0.5s ease;
}

.btn-vsmall:active {
  transform: translateY(1px);
}

.btn-vsmall:hover {
  border-radius: 10px;
}

.btn-vsmall:disabled {
  background: var(--brand-colour2);
  border: 1px solid #CCC;
  color: #CCC;
  box-shadow: none;
}

h1 {
  font-family: 'Work Sans';
  font-size: 72px;
  font-weight: 800;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

h2 {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 50px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
}

h3 {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 30px;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 0px;
}

h4 {
  font-family: 'Work Sans';
  font-size: 20px;
  font-weight: 400;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

select {
  outline: none;
  border-radius: 2px;
  border: 1px solid #CCC;
  margin-bottom: 5px;
  padding: 5px;
  font-size: 1rem;
  color: black;
  background: white;
  margin-left: 5px;
  box-shadow: 1px 2px 3px -1px #CCC;
}

select:disabled {
  border: solid 1px #CCC;
  color: #CCC;
}

input {
  -webkit-appearance: none;
  outline: none;
  border-radius: 2px;
  border: 1px solid #aaa;
  margin-bottom: 5px;
  padding: 5px;
  font-size: 1rem;
  font-weight: lighter;
  color: black;
  background: white;
  margin-left: 5px;
}

input:disabled {
  background: #EEE;
  color: #444;
}

a {
  text-decoration: none;
  color: black;
}

a:hover {
  color: var(--brand-colour3);
}

.flex-col {
  display: flex;
  flex-flow: column;
}

.flex-row {
  display: flex;
  flex-flow: row;
}

.flex-center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.flex-end {
  align-items: flex-end;
}

.flex-start {
  align-items: flex-start;
}

.flex-fill {
  flex: 1;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-between {
  justify-content: space-between
}

::placeholder,:-ms-input-placeholder, ::-ms-input-placeholder {
  color: #BBB;
  opacity: 1; /* Firefox */
}

.pageBody {
  flex: 1;
  flex-direction: column;
  display: flex;
  padding: 20px;
  color: black;
  font-size: 1.2rem;
  font-family: inherit;
  background-color: white;
  min-height: calc(100vh - 64px - 360px);
}

/** Hack to get round the top: -64px in pages with transparent header */
.whitePageBody {
  height: 100%;
  width: 100%;
  background-color: white;
}

.large-card {
  background: white;
  box-shadow: 0px 4px 20px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.small-card {
  background: white;
  box-shadow: 0px 2px 10px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.paper {
  background: white;
  box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

/* default */
.svgIcon {
  height: 24px;
  width: 24px;
}

.btn .svgIcon {
  height: 12px;
  width: 12px;
  margin-right: 5px;
}

@media (max-width: 640px) {
  .pageBody {
      font-size: 1rem;
      padding: 0px;
  }
  h1 {
    font-size: 56px;
  }
  h2 {
    font-size: 40px;
  }
  h3 {
    font-size: 30px;
  }
  h4 {
    font-size: 18px;
  }
}

@media (max-width: 375px) {
  h1 {
    font-size: 42px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 16px;
  }
}
