body {
  font-family: 'Assistant', sans-serif;
  box-sizing: border-box;
  /* background-image is loaded and set dynamically */
  background-color: white;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-attachment: fixed;
  background-position-x: center;
  margin: 0;
  padding: 0;
}
*, *:before, *:after {
  box-sizing: inherit;
}

:root {
  --brand-colour1: #0C0D07; /* Charcoal */
  --brand-colour2: #F7F7F7; /* Salt */
  --brand-colour3: #3E01F7; /* Ultramarine */
  --brand-colour4: #FE6F81; /* Wild Melon */
  --brand-colour5: #D8FF72; /* Contra */
  --brand-colour6: #8AFCF9; /* Silica */
  --brand-colour7: #A8FFB0; /* Dragon */
}

.hideSmall {
  visibility: visible;
}

@media screen and (min-width: 0px) and (max-width: 640px) {
  .hideSmall { 
    display: none; 
    visibility: hidden;
  }
  body { 
    background-size: 640px auto;
  }
}
